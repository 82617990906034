import '@/styles/index.scss' // global css

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import utils from './utils/utils'
import './plugins/element.js'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import Storage from '@/utils/Storage.js';
import checkLogin from './utils/checkLogin'
import signOut from './utils/signOut'

Vue.component('v-icon', Icon)

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$utils = utils
Vue.prototype.$storage = Storage
Vue.prototype.$checkLogin = checkLogin
Vue.prototype.$signOut = signOut

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')