import Vue from 'vue';
import Vuex from 'vuex';
import Storage from '@/utils/Storage.js';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        userInfo: {},
        testInfo: {},
        hasLogin: false
    },
    mutations: {
        login(state, provider) {
            state.hasLogin = true
            state.userInfo = provider
            Storage.setStorage("userInfo", provider);
        },
        signOut(state) {
            state.hasLogin = false;
            state.userInfo = {};
            state.testInfo = {};
            Storage.clearStorage('userInfo');
            Storage.clearStorage('testInfo');
            Storage.clearStorage('assessmentList');
        },
        upDateTestInfo(state, provider) {
            state.testInfo = provider
            Storage.setStorage('testInfo', provider)
        },
        
    }
})

export default store