import base from '../api/base.js';


function checkLogin(type,noVisitUrl) {
    let state = getState();
    location.replace(getAuthorizeUri(type,state));
}
function getState(){
    const FULL_CHARTER = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopgrstuvwxyz';
    let state = '';
    for (let i = 0; i < 6; i++) {
        state += FULL_CHARTER[Math.floor(Math.random() * 52)];
    }
    return state;
}
function getAuthorizeUri(redirectType, state) {
    //redirectType: login-主动去登录  back-检查登录状态 
    //应用id
    let clientId = 'webapp';
    //授权中心地址
    let uaaUri = base.authorUrl;
    let redirect_uri =  encodeURIComponent(base.redirect_uri);
    return uaaUri + '/oauth/authorize?client_id=' + clientId + '&redirect_uri='+redirect_uri+'&response_type=code&scope=ceping_onsite&redirect_type=login&state=' + state;//&redirect_type=back
}
export default {
    checkLogin
};