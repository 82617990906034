// 主页
const home = [{
        path: '*',
        redirect: '/'
    },
    {
        path:"/",
        name: 'Home',
        component: () =>
            import ('../../views/Home'),
        meta: {
            title: '首页',
            requireAuth: true
        }
    },
    {
        name: 'selExam',
        component: () =>
            import ('../../views/selExam'),
        meta: {
            title: '选择考点',
            requireAuth: true
        }
    },
    {
        name: 'addGroup',
        component: () =>
            import ('../../views/addGroup'),
        meta: {
            title: '新建分组',
            requireAuth: true
        }
    },
    {
        name: 'selectRoom',
        component: () =>
            import ('../../views/selectRoom'),
        meta: {
            title: '选择考场',
            requireAuth: true
        }
    },
    {
        name: 'selectFz',
        component: () =>
            import ('../../views/selectFz'),
        meta: {
            title: '选择分组',
            requireAuth: true
        }
    },
    {
        name: 'examDetail',
        component: () =>
            import ('../../views/examDetail'),
        meta: {
            title: '测评',
            requireAuth: true
        }
    },
    {
        name: 'callback.html',
        component: () =>
            import ('../../views/loginCallback'),
        meta: {
            title: '登录中'
        }
    },
    {
        name: '404.html',
        component: () =>
            import ('../../views/404'),
        meta: {
            title: '404'
        }
    }
]
export default home