let storage = {
    //保存缓存
    setStorage(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    //读取缓存
    getStorage(key) {
        return JSON.parse(localStorage.getItem(key));
    },
    // 清除缓存
    clearStorage(key) {
        localStorage.removeItem(key);
    }
}
export default storage;