/**
 * api接口的统一出口
 */

import base from './base'; // 导入接口域名列表
import axios from '@/axios'; // 导入axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const api = {}
    //#########################登录注册###########################//
api.login = params => axios.post(`${base.default}/assessment/account/login`, qs.stringify(params)); // 登录
api.addGroup = params => axios.post(`${base.default}/assessment/grouping/addGroup`, qs.stringify(params, { arrayFormat: 'repeat', allowDots: true })); // 新增分组和编号
api.changeGroup = params => axios.post(`${base.default}/assessment/grouping/changeGroup`, qs.stringify(params, { arrayFormat: 'repeat', allowDots: true })); // 考生换组
api.getFreeExamineesList = params => axios.post(`${base.default}/assessment/grouping/getFreeExamineesList`, qs.stringify(params)); // 查询未分组考生列表
api.getGroupWithDetailList = params => axios.post(`${base.default}/assessment/grouping/getGroupWithDetailList`, qs.stringify(params)); // 查询考点分组列表
api.getGroupBriefList = params => axios.post(`${base.default}/assessment/grouping/getGroupBriefList`, qs.stringify(params)); // 查询分组列表(不带考生信息)
api.addWorks = params => axios.post(`${base.default}/assessment/testing/addWorks`, qs.stringify(params)); // 新增作品
api.chooseQuestion = params => axios.post(`${base.default}/assessment/testing/chooseQuestion`, qs.stringify(params)); // 小组选题
api.getLiveInfo = params => axios.post(`${base.default}/assessment/testing/getLiveInfo`, qs.stringify(params)); // 获取直播信息(推流地址)
api.getQuestionList = params => axios.post(`${base.default}/assessment/testing/getQuestionList`, qs.stringify(params)); // 查询题目列表
api.getTestingDetail = params => axios.post(`${base.default}/assessment/testing/getTestingDetail`, qs.stringify(params)); // 查询考试详情
api.deleteWorks = params => axios.post(`${base.default}/assessment/testing/deleteWorks`, qs.stringify(params,{ arrayFormat: 'repeat', allowDots: true })); // 删除作品


api.getSecurityTokenWithSign = params => axios.post(`${base.default}/ossUpload/getSecurityTokenWithSign`, qs.stringify(params)); // 获取oss临时安全令牌带有签名和回调
api.authLogin = params => axios.get(`${base.default}/login/token`, {params:params}); // 登录
api.refreshToken = params => axios.post(`${base.default}/login/refreshToken`, qs.stringify(params)); // 刷新token

export default api;

// const that = this;
// let param = {
//   token: that.userInfo.token,
//   sitesId: that.testInfo.sitesId
// }
// that.$api.getGroupList(param).then(res => {
//   if (res.success) {

//   } else {
//    that.$message(res.msg)
//   }
// })