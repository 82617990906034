import modules from './modules'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Storage from '@/utils/Storage.js'
import checkLogin from '../utils/checkLogin'

Vue.use(VueRouter)

const routes = [...modules]
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});
const router = new VueRouter({
    mode: 'history',
    routes: routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.length === 0){
        
    }
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
        let userInfo = Storage.getStorage('userInfo');
        console.log(userInfo)
        if (userInfo) {
            if(!localStorage.getItem("testInfo")&&to.name!='selExam'){
                next({
                    path: '/selExam'
                })
                
            }
           else{
            next();
           }
        } else {
            // next({
            //     path: '/login',
            //     query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
            // })
            checkLogin.checkLogin('login');
        }
    } else {
        next();
    }
});

export default router