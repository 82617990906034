import base from '../api/base.js';
let envVariable = {
    // 测试环境
    bucketName: 'artapp-test-bucket',
}
if (base.isFormal == 1) {
    envVariable = {
        //正式环境
        bucketName: 'artapp-dev-bucket',
    }
}

const common = {
    ossUrl: "https://" + envVariable.bucketName + ".oss-cn-beijing.aliyuncs.com/", //oss服务器地址
    ossUrl0: "//" + envVariable.bucketName + ".oss-cn-beijing.aliyuncs.com/", //oss服务器地址
    defaultUserPic: "https://" + envVariable.bucketName + ".oss-cn-beijing.aliyuncs.com/app/default_avatar.png", //默认用户图片
    defaultVideoPic: "https://" + envVariable.bucketName + ".oss-cn-beijing.aliyuncs.com/app/videoDefaultCover.png", //默认视频图片
    defaultLiveBg: "https://" + envVariable.bucketName + ".oss-cn-beijing.aliyuncs.com/app/live_cover1.png", //默认直播封面
    userPicOssSuffix50: "?x-oss-process=image/resize,m_mfit,h_50,w_50",
    userPicOssSuffix100: "?x-oss-process=image/resize,m_mfit,h_100,w_100",
    coverOssSuffix: "?x-oss-process=image/resize,m_mfit,w_400",
    phoneTest: /^1[0-9]{10}$/, //手机号正则表达式
    emailTest: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, //邮箱正则表达式
    idCardTest: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, //身份证正则表达式
    ossVideoCoverSuffix: "?x-oss-process=video/snapshot,t_10000,m_fast", //oss视频第一帧
    // 测试环境
    APPID: "wx56ebf3046951056c", //公众号APPID
    baseUrl: "https://ceshi.artapp.cn/artappWeChat", //项目目录

    //正式环境
    // APPID:"wx54e02f329915084c",//公众号APPID
    // baseUrl:"https://www.artapp.cn/artappWeChat",//项目目录
}

function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}
//时间戳转换
function formatTime(date, type) {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();

    let hour = newDate.getHours();
    let minute = newDate.getMinutes();
    let second = newDate.getSeconds();

    switch (type) {
        case "YYYY-MM-DD":
            return [year, month, day].map(formatNumber).join('-');
        case "YYYY.MM.DD":
            return [year, month, day].map(formatNumber).join('.');
        case "YYYY.MM.DD hh:mm":
            return [year, month, day].map(formatNumber).join('.') + ' ' + [hour, minute].map(formatNumber).join(':');
        default:
            return [year, month, day].map(formatNumber).join('.') + ' ' + [hour, minute, second].map(formatNumber).join(':');
    }
}
//获取url参数
function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
//倒计时
function timeCount(endtimestr, type) {
    let str = "";
    if (!endtimestr && endtimestr === undefined) {
        return false;
    }
    let endTimer = new Date(endtimestr).getTime();
    let curDate = new Date().getTime();
    var ts = endTimer - curDate;
    if (ts > 0) {
        let dd = formatNumber(parseInt(ts / 1000 / 60 / 60 / 24, 10));
        let hh = formatNumber(parseInt(ts / 1000 / 60 / 60 % 24, 10));
        let mm = formatNumber(parseInt(ts / 1000 / 60 % 60, 10));
        let ss = formatNumber(parseInt(ts / 1000 % 60, 10));
        switch (type) {
            case "DD天hh时mm分ss秒":
                str = dd + '天' + hh + '时' + mm + '分' + ss + '秒';
            case "DD天":
                str = dd + '天';
            case "hh:mm:ss":
                str = hh + ':' + mm + ':' + ss;
            default:
                str = dd + '天' + hh + '时' + mm + '分' + ss + '秒';
        }
    } else {
        str = '00天00时00分00秒';
    }
    return str;
}
/*uuid命名*/
function uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;

}

function check360() {
    if (navigator.userAgent.indexOf('Safari') != -1) {
        // 检测极速内核下的360浏览器
        var uas = navigator.userAgent.split(' '),
            result = false;
        // 排除ua自带标志的双核浏览器, 余下chrome,chromium及360浏览器
        if (uas[uas.length - 1].indexOf('Safari') == -1) {
            return result;
        }
        for (var key in navigator.plugins) {
            // np-mswmp.dll文件在chromium及chrome未查询到
            if (navigator.plugins[key].filename == 'np-mswmp.dll') {
                return !result;
            }
        }
        return result;
    } else {
        // 检测兼容模式下的360浏览器
        /**
         *
         * 截止20170731得到有差异的数据项
         *                                                      IE	搜狗	2345	遨游	QQ	360
         * _IE_DEVTOOLBAR_CONSOLE_COMMAND_LINE	                 ×	√	√	√	√	√
         * window.maxConnectionsPerServer	                    10(http协议下值为6)	10	10(http协议下值为6)	10	6	10(http协议下值为6)
         * navigator.userAgent	                                ×	√/×(IE9及以上)	×	×	√	×
         * window.external		                                    [Sogou Explorer Object]
         * navigator.msDoNotTrack (IE10及以下)	                1	0	0	0	0	1
         * window.doNotTrack(IE11)	                            1	null	null	null	null	1
         * navigator.msPointerEnabled	                        TRUE	TRUE	FALSE	FALSE	TRUE	TRUE
         * window.screenLeft - window.screenX != 8 (IE9及以上)	FALSE	TRUE	FALSE	FALSE	TRUE	TRUE
         * console.count(仅测试了IE7、8)		                    undefined	undefined	undefined	undefined	undefined
         *
         */
        return ((navigator.msPointerEnabled == undefined ? true : navigator.msPointerEnabled) &&
            (navigator.msDoNotTrack == 1 || window.doNotTrack == 1) &&
            ((Number(window.screenX) ? (window.screenLeft - window.screenX != 8) : false) ||
                ((navigator.userAgent.indexOf('MSIE 7.0') != -1 || navigator.userAgent.indexOf('MSIE 8.0') != -1) && console.count == undefined)));
    }
}
function addOssurlHeader(url){
    if (url.indexOf('blob:http://') !=-1||url.indexOf('.oss-cn-beijing.aliyuncs.com')!=-1) return url; //过了二进制文件
	return common.ossUrl + url;
}
export default {
    envVariable,
    common,
    formatTime,
    getUrlParam,
    timeCount,
    uuid,
    check360,
    addOssurlHeader
};