/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";
import router from "./router";
import store from "./store/index";
import { Message } from "element-ui";

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = (msg) => {
  Message.error(msg);
};

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: "/callback.html",
    query: {
      isRefreshToken: 1,
    },
  });
};

// 创建axios实例
var instance = axios.create({
  timeout: 1000 * 20,
});
// 设置post请求头
instance.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    // const tokens = store.state.tokenStatus.token;
    // console.log(tokens);
    // token && (config.headers.Authorization = token);
    return config;
  },
  (error) => Promise.error(error)
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  // res => res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res.data),

  (response) => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    if (response.data.code == "902") {
      setTimeout(() => {
        toLogin();
      }, 1000);
    }
    return response.data;
  },
  (error) => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      console.log(error.response.status);
      switch (error.response.status) {
        case 400:
          Message.error("400:错误请求");
          break;
        case 401:
          Message.error("401:未授权，请重新登录");
          break;
        case 403:
          Message.error("403:拒绝访问");
          break;
        case 404:
          Message.error("404:请求错误,未找到该资源");
          break;
        case 405:
          Message.error("405:请求方法未允许");
          break;
        case 408:
          Message.error("408:请求超时");
          break;
        case 500:
          Message.error(" 500:服务器端出错");
          break;
        case 501:
          Message.error("501:网络未实现");
          break;
        case 502:
          Message.error("502:网络错误");
          break;
        case 503:
          Message.error("503:服务不可用");
          break;
        case 504:
          Message.error("504:网络超时");
          break;
        case 505:
          Message.error("505:http版本不支持该请求");
          break;
        default:
          Message.error(`连接错误${error.response.status}`);
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        Message.error("服务器响应超时，请刷新当前页");
      } else {
        Message.error("连接服务器失败");
      }
    }
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response);
  }
);

export default instance;
