import base from "../api/base";
const signOut = function(){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let redirect_uri =  encodeURIComponent(base.redirect_uri);
    let signOutUri = base.authorUrl+'/oauth/logout?token='+userInfo.token+'&redirect_uri='+redirect_uri;
    console.log(signOutUri);
    location.replace(signOutUri);
}
export default{
    signOut
}