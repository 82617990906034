/**
 * 接口域名的管理
 */
//
let domainurl = "";
let isFormal = 1; //0：测试库；1：正式库
let restPath = '/ArtAppInst2';
let authorUrl; //授权中心
let redirect_uri;//授权回调
let baseUrl;

if (process.env.NODE_ENV == 'development') {
    if (isFormal === 0) {
        domainurl = '/proxyCeshi';
        authorUrl = '//frp.artapp.cn/AuthServer';
        redirect_uri='http://127.0.0.1:8089/callback.html';
        baseUrl = 'http://127.0.0.1:8089';
    } else {
        domainurl = '/proxyReal';
        baseUrl = 'http://127.0.0.1:8089';
    }


} else if (process.env.NODE_ENV == 'production') {
    if (isFormal === 0) {
        domainurl = 'https://onsite.artapp.cn';
        authorUrl = '//ceshi.artapp.cn/AuthServer';
        redirect_uri='https://onsite.artapp.cn/callback.html'
        baseUrl = 'https://onsite.artapp.cn';
    } else {
        domainurl = 'https://onsite.i-ceping.net';
        authorUrl = '//www.artapp.cn/AuthServer';
        redirect_uri='https://onsite.i-ceping.net/callback.html';
        baseUrl = 'https://onsite.i-ceping.net';
    }
}

const base = {
    default: domainurl + restPath,
    baseUrl:baseUrl,
    isFormal: isFormal,
    authorUrl:authorUrl,
    redirect_uri:redirect_uri
}

export default base;