<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex';
import store from '@/store';
export default {
  beforeCreate: function () {
    console.group('beforeCreate 创建前状态*********************》');
    var userInfo = this.$storage.getStorage('userInfo');
     if(userInfo){
       console.log(userInfo)
      store.commit('login', userInfo)
    }
    var testInfo = this.$storage.getStorage('testInfo');
    if(testInfo){
      store.commit('upDateTestInfo', testInfo)
    }
  },
  created: function () {
    console.group('created 创建完毕状态*********************》');
  },
  beforeMount: function () {
    console.group('beforeMount 挂载前状态*********************》');
  },
  mounted: function () {
    console.group('mounted 挂载结束状态*********************》');
  },
  beforeUpdate: function () {
    console.group('beforeUpdate 更新前状态*********************》');
  },
  updated: function () {
    console.group('updated 更新完成状态*********************》');
  },
  beforeDestroy: function () {
    console.group('beforeDestroy 销毁前状态*********************》');
  },
  destroyed: function () {
    console.group('destroyed 销毁完成状态*********************》');
  },
  methods: {
    ...mapMutations(['login'])
  }
};
</script>
<style lang="scss">
@import "@/assets/sass/base.scss";
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
